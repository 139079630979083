import { Link } from "react-router-dom";
import "./HomePage.css";

const HomePage = () => {
  return (
    <div className="HomePage">
      <h3>Hello I'm Ruwaidah Alfakhri ...</h3>
      <h4>Full Stack Web Developer</h4>
      <Link to ="/projects">View Projects</Link>
    </div>
  );
};

export default HomePage;
