import "./App.css";
import AboutMe from "./components/AboutMe/AboutMe";
import NavBAr from "./components/NavBar/NavBar";
import RoutesComponent from "./components/RoutesComponent/RoutesComponent";

function App() {
  return (
    <div className="App">
      <NavBAr />


      <RoutesComponent />
    </div>
  );
}

export default App;
