import { Link } from "react-router-dom";
import "./Projects.css";
import movieKnight from "../../Images/movieKnight.png";
import Boo from "../../Images/Boo.png";
import hackorsnooze from "../../Images/hackorsnooze.png";

const Projects = () => {
  return (
    <div className="Projects">
      <div className="MovieKnight">
        <Link
          className="img-link"
          to="https://movieknight.onrender.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          <img src={movieKnight} />
        </Link>
        <div className="project-details">
          <Link
            to="https://movieknight.onrender.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            MovieKnight
          </Link>
          <div>
            <ul>
              <li>Built with HTML, CSS, React, Node.</li>
              <li>Mobile first application.</li>
              <li>
                User can search for a movies by Zipcode with a third part API.
              </li>
              <li>Redux for state management</li>
              <li>
                Use third party API's to show movies that's in theater now
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="Boo">
        <Link className="img-link" to="https://boo-7emc.onrender.com/">
          {" "}
          <img src={Boo} />
        </Link>
        <div className="project-details">
          <Link
            to="https://boo-7emc.onrender.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Boo
          </Link>
          <ul>
            <li>
              Search for and display pet listings based on pet characteristics,
              location, and status.
            </li>
            <li>React Js for frontend.</li>
            <li>Redux for state management.</li>
            <li>React Hook Form for form validation.</li>
            <li>Node js and express for Backend.</li>
            <li>Using third party API(Pet Finder) for getting pets Data</li>
          </ul>
        </div>
      </div>

      <div className="MovieKnight Hack-or-Snooze">
        <Link
          className="img-link"
          to="https://ruwaidah.github.io/Hack-or-Snooze/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          <img src={hackorsnooze} />
        </Link>
        <div className="project-details">
          <Link
            to="https://ruwaidah.github.io/Hack-or-Snooze/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            Hack or Snooze
          </Link>
          <div>
            <ul>
              <li>Built with HTML, CSS, Java SCript</li>
              <li>Create, edit, and delete Story.</li>
              <li>NodeJs for Backend</li>
              <li>PostgreSQL for dataBase</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
