import "./ContactMe.css";
import Linkedin from "../../Images/linkedin.png";
import Github from "../../Images/github.png";
const ContactMe = () => {
  return (
    <div className="ContactMe">
      <div className="email-contact">
        <h5>Email:</h5>
        <p>ruwaidah.riyadh@gmail.com</p>
      </div>
      <div className="links">
        <div className="github-linkedin">
          <a
            href="https://github.com/Ruwaidah"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            <img src={Github} alt="github-logo" />
          </a>
          <a
            href="https://github.com/Ruwaidah"
            target="_blank"
            rel="noopener noreferrer"
          >
            Github
          </a>
        </div>
        <div className="github-linkedin">
          {" "}
          <a
            href="https://www.linkedin.com/in/ruwaidah-a-930b9a8b/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Linkedin} alt="linkedin-logo" />
          </a>
          <a
            href="https://www.linkedin.com/in/ruwaidah-a-930b9a8b/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Linkedin
          </a>{" "}
        </div>
      </div>
    </div>
  );
};

export default ContactMe;
