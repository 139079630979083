import "./Skills.css";

const Skills = () => {
  return (
    <div className="Skills">
      <div className="Education">
        <h3>Higher Education:</h3>
        <ul>
          <li>Bachelor's degree in Mathematics and Computer Science.</li>
        </ul>
      </div>
      <div className="Technical">
        <h3>Technical Skills:</h3>
        <ul>
          <li>HTML</li>
          <li>CSS</li>
          <li>SCSS - CSS Compiler</li>
          <li>JavaScript</li>
          <li>npm</li>
          <li>React</li>
          <li>Redux</li>
          <li>Express NodeJs</li>
          <li>API's</li>
          <li>MySQL</li>
          <li>Relational Database</li>
          <li>PostgreSQL</li>
          <li>Knex</li>
          <li>Python</li>
          <li>Git / Github</li>
        </ul>
      </div>
    </div>
  );
};

export default Skills;
