import { Routes, Route } from "react-router";
import "./RoutesComponent.css";
import AboutMe from "../AboutMe/AboutMe";
import ContactMe from "../ContactMe/ContactMe";
import Projects from "../Projects/Projects";
import Skills from "../Skills/Skills";
import PageNotFind from "../PageNotFind/PageNotFind";
import HomePage from "../HomePAge/HomePage";

const RoutesComponent = () => {
  return (
    <div className="RoutesComponent">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route exact path="/aboutme" element={<AboutMe />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/skills" element={<Skills />} />
        <Route path="/contactme" element={<ContactMe />} />
        <Route path="*" element={<PageNotFind />} />
      </Routes>
    </div>
  );
};

export default RoutesComponent;
